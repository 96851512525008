import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Img from "gatsby-image";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import WorkSans from 'typeface-work-sans';
import Chip from '@material-ui/core/Chip';

// import {navigate} from "gatsby"

// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {useTheme} from '@material-ui/core/styles';

// import Layout from "../components/layout";

import {navigate} from "gatsby"

const useStyles = makeStyles(theme => ({
  card: {
    width: '320px',
    height: '420px',
    margin: '2.5vw',
    padding: '20px',
    boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 4px 12px 8px rgba(0,0,0,0.08)',
      cursor: 'pointer',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      margin: '20px',
      height: ''
    }
  },
  heading: {
    padding: '5px 0px 10px',
    fontFamily: 'Work Sans',
    fontWeight: 800,
    fontSize: 30,
    lineHeight: 1,
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: 30,
      // paddingBottom: '20px'
    }
  },
  detail: {
    padding: '8px'
  },
  chip: {
    margin: '5px'
  },
  button: {
    // margin: '8px 0px 0px'
  }
}));

export default function ExampleCard(props) {
  // const theme = useTheme();

  // const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  const handleClick = () => {
    navigate(props.listData.link, {state: {
        // company: props.drinkData
      }})
    // props.setOpenCompany(props.drinkData)
    // props.openCompanyDialog()
  }

  // let drinkImageUrl = `https://logo.clearbit.com/${props.drinkData.domain}?size=80`

  return (<Card className={classes.card} variant='outlined' elevation='0' onClick={handleClick}>
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        backgroundColor: '',
        height: '100%',
        padding: '0px 20px',
        marginTop: '-5px'
      }}>
      <Grid item="item" style={{
          width: '100px',
          height: '50px',
          backgroundColor: '',
          // margin: '-5px'
        }}>
        <img id='card-img' width='100px' height='50px' alt='Logo' src={props.listData.image} style={{
            borderRadius: '5px',
            objectFit: 'contain'
          }}/>
      </Grid>
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          // backgroundColor: 'red',
          // height: '220px',
          padding: '15px 0px'
        }}>
        <Typography className={classes.heading} align='center' utterBottom="utterBottom" variant="h5" component="h2">
          {props.listData.name}
        </Typography>
        <Grid container="container" direction='row' justify="center" className={classes.chipContainer}>
          {props.listData.startups && <Chip className={classes.chip} label={props.listData.startups + ' Startups'} variant="outlined" color='primary' clickable='true'/>}
          {props.listData.year && <Chip className={classes.chip} label={props.listData.year + ' Edition'} variant="outlined" color='primary' clickable='true'/>}
        </Grid>
        <Typography className={classes.detail} align='center' variant="body2" color="textSecondary" component="p">
          {props.listData.authorString}
        </Typography>
      </Grid>
      {props.listData.startups && <Button className={classes.button} size="medium" variant='contained' color="primary">
        View List
      </Button>}
    </Grid>
  </Card>);
}
