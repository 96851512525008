import SEO from "../components/seo"

import React, {useState, useEffect} from 'react';

import ListCard from '../components/ListCard'
// import {DrinkData} from '../data/DrinkData.js'
import MenuAppBar from '../components/AppBar'
// import Filter from '../components/Filter'
// import Sort from '../components/Sort'
// import Grow from '@material-ui/core/Grow';
import Loader from '../components/Loader'

// import CircularProgress from '@material-ui/core/CircularProgress';

// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
// import {navigate} from "gatsby"
import Grid from '@material-ui/core/Grid';

// import getFirebase from '../utils/firebase';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebase from 'firebase/app';
import "firebase/auth";
import Grow from '@material-ui/core/Grow';

// import CompanyPage from '../components/CompanyPage'

// import {TwitterTimelineEmbed} from 'react-twitter-embed';

// import CircularProgress from '@material-ui/core/CircularProgress';

import {makeStyles} from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

import Layout from "../components/layout";

// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';

import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import firebase from 'firebase/app';

// import Button from '@material-ui/core/Button';

// import Chip from '@material-ui/core/Chip';
import 'typeface-work-sans';

// import Slide from '@material-ui/core/Slide';

// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';

// const uiConfig = {
//    Popup signin flow rather than redirect flow.
//   signInFlow: 'popup',
//    Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: '/',
//    We will display Google and Facebook as auth providers.
//   signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
// };

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'Work Sans',
    fontWeight: '800',
    fontSize: '60px',
    backgroundColor: '',
    lineHeight: '1',
    textAlign: 'center',
    paddingBottom: '0px',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '48px'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '45px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '40px'
    }
  },
  subtitle: {
    fontFamily: 'Work Sans',
    fontWeight: '400',
    fontSize: '30px',
    backgroundColor: '',
    lineHeight: '1',
    textAlign: 'center',
    paddingTop: '10px',
    letterSpacing: '-1px',
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '28px'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '25px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '20px'
    }
  },
  cardContainer: {
    padding: '20px',
    backgroundColor: 'red',
    [
      theme
        .breakpoints
        .up('sm')
    ]: {
      // height: '550px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      marginBottom: '20px'
    }
  },
  dialog: {
    padding: '40px',
    // textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '20px'
    }
  }
}));

export default function Catalog() {
  // const [companyDialogIsOpen, setCompanyDialogIsOpen] = useState(false);
  const [animatedIn, setAnimatedIn] = useState([]);
  // const firebase = getFirebase();
  // const [currentCompany, setCurrentCompany] = useState(false);

  // const [dialogIsOpen, setDialogIsOpen] = useState(false);
  // const [companyDialogIsOpen, setCompanyDialogIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [activeSort, setActiveSort] = React.useState('rank');
  // const [activeFilter, setActiveFilter] = React.useState('all');
  // const [openCompany, setOpenCompany] = React.useState(null);

  let narwhalList = {
    name: "The UofT Narwhal List",
    image: 'https://comfable.com/wp-content/uploads/2015/04/IMC.jpg',
    link: '/narwhal',
    authorString: "By UofT's Impact Centre",
    startups: 40,
    year: 2020
  }

  let linkedInList = {
    name: 'LinkedIn Top Startups',
    image: 'https://www.vippng.com/png/detail/369-3698815_download-high-resolution-linkedin.png',
    link: '/linkedin',
    authorString: "By LinkedIn, Inc.",
    startups: 10,
    year: 2020
  }

  let comingSoonList = {
    name: 'More Lists Coming Soon',
    image: 'https://image.flaticon.com/icons/png/512/477/477164.png',
    link: 'https://solomongomez.com',
    authorString: "Send over a message if you have any suggestions."
  }

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);

    }

    if (animatedIn.length === 0) {
      console.log('yeet')
      let animatedInArray = []
      setTimeout(function() {
        animatedInArray.push('narwhalList')
        setAnimatedIn(animatedInArray)
      }, 200)
      setTimeout(function() {
        animatedInArray.push('linkedInList')
        setAnimatedIn(animatedInArray)
      }, 300)
      setTimeout(function() {
        setAnimatedIn(animatedInArray.concat('comingSoonList'))
      }, 400)
    }
  }, [isLoaded, animatedIn]);

  const finalPage = isLoaded
    ? (<Layout>
      <SEO title="Home"/>
      <MenuAppBar/>
      <Fade in="in">
        <Grid container="container" direction="column" justify="center" alignItems="center" style={{
            padding: '40px 40px 100px',
            minHeight: '100vh',
            // backgroundColor: 'red'
          }}>
          <Grid direction={matches
              ? "column"
              : "row"} justify='center' alignItems="center" style={{
              padding: '30px 20px',
              // backgroundColor: 'blue'
            }}>
            <Grid item="item" style={{
                backgroundColor: ''
              }}>
              <Typography className={classes.title}>FreshStartup</Typography>
              <Typography className={classes.subtitle}>Discover Top Canadian Startups 🇨🇦</Typography>
            </Grid>
          </Grid>
          <Grid container="container" direction='row' justify="center" alignItems="center" style={{
              // backgroundColor: 'green'
            }}>
            <Grow in="in">
              <div>
                <ListCard listData={narwhalList}/>
              </div>
            </Grow>
            <Grow in="in">
              <div>
                <ListCard listData={linkedInList}/>
              </div>
            </Grow>
            <Grow in="in">
              <div>
                <ListCard listData={comingSoonList}/>
              </div>
            </Grow>
          </Grid>
        </Grid>
      </Fade>
    </Layout>)
    : <Loader/>

  // Render
  return (finalPage)
}

// <CompanyDialog drinkData={currentCompany} open={companyDialogIsOpen} onClose={closeCompanyDialog}/>
